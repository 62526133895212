var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component c-slideshow c-slideshow--slider position-relative bg-color-surface z-index-2"},[_c('swiper',{ref:"slider",staticClass:"bg-color-lighter",attrs:{"options":_vm.swiper_options}},[_vm._l((_vm.data),function(slide){return _c('swiper-slide',{key:slide.id,staticClass:"c-slideshow__item position-relative"},[_c('div',{staticClass:"c-slideshow__media bg-color-dark text-default-on-dark position-relative",class:{
					'c-slideshow__media-gradient': slide.show_gradient,
				}},[(
						slide.primary_image.images &&
							slide.primary_image.images.length
					)?_c('picture',{staticClass:"c-slideshow__image utility-position-cover object-fit-cover"},[_vm._l((slide.primary_image.images),function(image,index){return _c('source',{key:index,attrs:{"srcset":_vm.$root.get_image_resizer_url(("" + (_vm.$root.proxy) + (image.image) + "?format=jpg&quality=80")),"alt":image.alt,"media":("(min-width: " + (image.breakpoint) + "px)")}})}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$root.get_image_resizer_url(("" + (_vm.$root.proxy) + (slide.primary_image.images[0].image) + "?format=jpg&quality=80"))),expression:"$root.get_image_resizer_url(`${$root.proxy}${slide.primary_image.images[0].image}?format=jpg&quality=80`)"}],staticClass:"utility-position-cover object-fit-cover",attrs:{"alt":slide.primary_image.images[0].alt}})],2):_vm._e(),_c('div',{staticClass:"c-slideshow__media-details position-absolute z-index-2 bottom-0 left-0 padding-b-12 padding-x-24 bp-768:display-none"},[(
							slide.secondary_image.images &&
								slide.secondary_image.images.length
						)?_c('div',{staticClass:"c-slideshow__logo margin-b-16"},[_c('img',{attrs:{"src":_vm.$root.get_image_resizer_url(("" + (_vm.$root.proxy) + (slide.secondary_image.images[0].image))),"alt":slide.secondary_image.images[0].alt,"width":"60"}})]):_vm._e(),_c('div',{staticClass:"c-slideshow__title font-legacy font-size-30 line-height-100"},[(slide.primary_link)?_c('navigation-link',{attrs:{"_class":"text-decoration-none text-default-on-dark display-inline-block","url":slide.primary_link}},[_vm._v(" "+_vm._s(slide.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(slide.title)+" ")])],1)])]),_c('div',{staticClass:"c-slideshow__details bp-768:z-index-2 bp-768:position-absolute bp-768:bottom-0 bp-768:left-0 bp-768:height-100 bp-768:width-100 bp-768:display-flex bp-768:align-items-center bp-768:text-default-on-dark"},[_c('div',{staticClass:"c-slideshow__details-inner padding-24 bp-768:padding-y-0 bp-1024:padding-x-64",staticStyle:{"max-width":"700px"}},[(
							slide.secondary_image.images &&
								slide.secondary_image.images.length
						)?_c('div',{staticClass:"c-slideshow__logo display-none margin-b-24 bp-768:display-block bp-1024:margin-b-32"},[_c('img',{attrs:{"src":_vm.$root.get_image_resizer_url(("" + (_vm.$root.proxy) + (slide.secondary_image.images[0].image))),"alt":slide.secondary_image.images[0].alt}})]):_vm._e(),_c('div',{staticClass:"c-slideshow__title display-none line-height-100 bp-768:display-block"},[(slide.primary_link)?_c('navigation-link',{attrs:{"_class":"text-decoration-none text-default-on-dark font-legacy font-size-36 bp-1024:font-size-48","url":slide.primary_link}},[_vm._v(" "+_vm._s(slide.title)+" ")]):_c('span',{staticClass:"text-decoration-none text-default-on-dark font-legacy font-size-36 bp-1024:font-size-48"},[_vm._v(" "+_vm._s(slide.title)+" ")])],1),(slide.teaser)?_c('div',{staticClass:"c-slideshow__teaser text-muted margin-t-12 bp-768:text-default-on-dark bp-1024:margin-t-16",class:{
							'margin-b-16 bp-768:margin-b-24 bp-1024:margin-b-32':
								slide.links,
						},domProps:{"innerHTML":_vm._s(slide.teaser)}}):_vm._e(),(slide.links)?_c('ul',{staticClass:"c-slideshow__links reset-list"},_vm._l((slide.links),function(link){return _c('li',{key:link.link_id},[(
									_vm.is_fevo_page &&
										link.link_text
											.toLowerCase()
											.includes('buy')
								)?_c('navigation-link',{attrs:{"_class":"button --on-dark-alt","url":link.link_url,"isFevoTrackingButton":true}},[_vm._v(" "+_vm._s(link.link_text)+" ")]):_c('navigation-link',{attrs:{"_class":"button --on-dark-alt","url":link.link_url}},[_vm._v(" "+_vm._s(link.link_text)+" ")])],1)}),0):_vm._e()])])])}),(_vm.data.length > 1)?_c('div',{staticClass:"swiper-button-prev border-b border-t border-r text-brand\n\t\t\t\thover:bg-color-primary hover:text-on-primary\n\t\t\t\tbp-768:display-none \n\t\t\t",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('arrow-left-icon',{attrs:{"size":"24"}})],1):_vm._e(),(_vm.data.length > 1)?_c('div',{staticClass:"swiper-button-next border-b border-t text-brand\n\t\t\t\thover:bg-color-primary hover:text-on-primary\n\t\t\t\tbp-768:display-none \n\t\t\t",attrs:{"slot":"button-next"},slot:"button-next"},[_c('arrow-right-icon',{attrs:{"size":"24"}})],1):_vm._e()],2),(_vm.data.length > 1)?_c('ol',{staticClass:"c-slideshow__pagination display-none reset-list flex-direction-column align-items-center justify-content-center position-absolute z-index-2 bottom-0 right-0 height-100 bp-768:display-flex"},_vm._l((_vm.data),function(item,index){return _c('li',{key:item.id,staticClass:"c-slideshow__pagination-item cursor-pointer utility-flex-center position-relative",class:{ 'is-active': item.is_open },on:{"click":function($event){return _vm.go_to_slide(index)}}},[(
					item.primary_image && item.primary_image.images.length
				)?_c('img',{staticClass:"c-slideshow__pagination-thumb position-absolute top-50 right-100",attrs:{"src":_vm.$root.get_image_resizer_url(("" + (_vm.$root.proxy) + (item.primary_image.images[0].image))),"alt":item.primary_image.images[0].alt,"width":"128"}}):_vm._e()])}),0):_vm._e(),(_vm.data.length > 1)?_c('button',{staticClass:"c-slideshow__pause-play border-t border-b border-l cursor-pointer position-absolute bottom-0 right-0 reset text-brand transition z-index-3\n\t\t\thover:bg-color-primary hover:text-on-primary\n\t\t\tbp-768:border-none bp-768:text-white\n\t\t",on:{"click":function($event){$event.preventDefault();_vm.swiper_is_playing ? _vm.pause_swiper() : _vm.play_swiper()}}},[(!_vm.swiper_is_playing)?_c('play-circle-icon',{attrs:{"size":"1.5x"}}):_c('pause-circle-icon',{attrs:{"size":"1.5x"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }